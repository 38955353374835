import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import BackContact from "../components/backContact"
import QuestionDetail from "../components/questionDetail"

const Questions = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      question: file(relativePath: { eq: "question.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
      tohonImg: file(relativePath: { eq: "tohon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shikibetsuImg: file(relativePath: { eq: "shikibetsu.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const description =
    "皆様から、大阪の司法書士事務所の司法書士法人みつわ合同事務所に、よくいだだいている、ご質問についてお答えさせていただいております。お急ぎの方は、まずは、こちらのページをご参考にしてください。その他に分からないことや疑問点がありましたらお気軽にお問い合わせください。"

  const tohonQ = `登記事項証明書（登記簿謄本）はどこで取得できるの？`
  const tohonA = `最寄の法務局でどなたでも１通６００円で取得することが出来ます。
    また、インターネットバンキング（ペイジー）をご利用できる環境があれば、インターネットでユーザー登録をすることにより、１通５００円で郵送により取得することもできますので、ご利用下さい。
    `

  const onlineQ = `登記事項証明書のオンライン請求とは？`
  const onlineA = `登記・供託オンライン申請システムを利用することにより、登記事項証明書をオンラインで請求することができるようになっています。
これを利用することにより、確定申告時期など、法務局が混み合う時に待たされることなく、請求した登記事項証明書を郵便で受領することができます。
ただし、平日の午前8時30分から午後9時までと利用時間が決まっていますのでご注意ください。
また、利用するには、申請者情報登録が必要となっております。下記に、申請者情報登録の方法と、登記事項証明書の請求方法の簡単な解説動画を用意しましたので、参考にしてください。`

  const kenrisyoQ = `権利証とは？`
  const kenrisyoA = `従来は、登記を申請するときは、書面でのみ申請することになっておりましたが、不動産登記法改正により、法務大臣の指定を受けた登記所（オンライン庁）では、インターネットでも申請できることになりました。
  オンライン庁に指定された後は、登記済証（いわゆる権利証）の交付に代えて、登記名義人に対し「登記識別情報」を通知することとなりました。
  したがいまして、登記識別情報が従来の権利証に代わるものとなりますので、大切に保管頂きますようお願いします。`

  const syozaiChibanQ = `登記上の所在と住所が違うのは何故ですか？ `
  const syozaiChibanA = `建物の登記上の所在とは、不動産登記において使用される「地番」で表示されております。「地番」とは、土地に付された番号のことです。そうすることにより、登記された建物が、どの土地の上に建っているのかを示すことになっております。
一方、「住所（住居表示）」とは、住民登録や郵便の宛先として市町村により決められたものであり、建物の所在と住所は異なる概念のものです。
よって、違うのは当然なのですが、地域によっては、地番をそのまま住所として利用しているところもあるので、同じになることもあります。 `

  const kaisuQ = `マンションは「１０階建」なのに「１階建」になっているの間違いではないですか？ `
  const kaisuA = `分譲マンションの場合は、一棟の建物の表示の構造欄でマンション全体の階数を表示し、専有部分の建物の表示の構造欄で、各戸の室内の現況を表示します。「鉄筋コンクリート造１階建」等となっているのは、その室内だけを見た場合に、階段がなく、メゾネットタイプではないことを示しています。 
`

  const hiyouQ = `登記費用はどのように決まるのですか？ `
  const hiyouA = `登記費用は主に、司法書士報酬と登録免許税等の実費の合計金額になります。司法書士報酬は自由化されていますので、各司法書士によって異なります。
  登録免許税は、登記申請時に納付しなければならない税金で、司法書士がお客様から預かったお金で代わりに納付することが一般的です。
  登記の種類や、租税特別措置法による軽減税率が適用できるかどうかによって税金の額が変わります。
  例えば、所有者の住所の変更登記や、抵当権抹消登記は、不動産１個につき1,000円、所有権移転は、固定資産税評価額の1,000分の20、または条件によって、1,000分の15や、1,000分の4、1,000分の3、となる場合もあります。
`

  return (
    <Layout>
      <SEO
        description={description}
        title="Ｑ＆Ａ　よくあるご質問 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.question.childImageSharp.original.src}
        pageimgw={data.question.childImageSharp.original.width}
        pageimgh={data.question.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"question"}
          fluid={data.question.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"Q＆A"}
        />
        <section className="question">
          <div className="question__container">
            <h1 className="question__title">よくあるご質問</h1>
            <div className="question__service__list">
              <section className="question__item">
                <QuestionDetail question={tohonQ} answer={tohonA} />
                <div className="question__img">
                  <h2>【登記事項証明書見本】</h2>
                  <figure>
                    <Img
                      fluid={data.tohonImg.childImageSharp.fluid}
                      alt={"登記事項証明書見本"}
                    />
                  </figure>
                </div>
              </section>

              <section className="question__item">
                <QuestionDetail question={onlineQ} answer={onlineA} />
                <div className="question__videos">
                  <div className="question__videos__item">
                    <h2>【オンライン申請利用者登録】</h2>
                    <iframe
                      src="https://www.youtube.com/embed/eztJlvoVjDQ"
                      frameBorder="0"
                      allowFullScreen
                      title="video1"
                    ></iframe>
                  </div>
                  <div className="question__videos__item">
                    <h2>【登記事項証明書オンライン請求】</h2>

                    <iframe
                      src="https://www.youtube.com/embed/5650Oi3kcnY"
                      frameBorder="0"
                      allowFullScreen
                      title="video2"
                    ></iframe>
                  </div>
                </div>
              </section>
              <section className="question__item">
                <QuestionDetail question={kenrisyoQ} answer={kenrisyoA} />
                <div className="question__img">
                  <h2>【登記識別情報通知】</h2>
                  <figure>
                    <Img
                      fluid={data.shikibetsuImg.childImageSharp.fluid}
                      alt={"登記識別情報通知（現行様式）"}
                    />
                  </figure>
                </div>
              </section>
              <section className="question__item">
                <QuestionDetail
                  question={syozaiChibanQ}
                  answer={syozaiChibanA}
                />
              </section>
              <section className="question__item">
                <QuestionDetail question={kaisuQ} answer={kaisuA} />
              </section>
              <section className="question__item">
                <QuestionDetail question={hiyouQ} answer={hiyouA} />
              </section>
            </div>
          </div>
        </section>
        <BackContact link={"/"} linkName={"Topページ"} />
      </div>
    </Layout>
  )
}

export default Questions
