import React from "react"

const QuestionDetail = ({ question, answer }) => {
  return (
    <>
      <dl>
        <dt>{question}</dt>
        <dd>{answer}</dd>
      </dl>
    </>
  )
}

export default QuestionDetail
